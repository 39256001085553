import { Injectable } from '@angular/core';
import { BlacklistClientUsersRequest } from '@app/shared/interfaces/blacklist-client-users/blacklist-client-users.request';
import { BlacklistClientUser } from '@app/shared/interfaces/blacklist-client-users/blacklist-client-users.response';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable()
export class BlacklistClientUsersService extends AbstractCrudService<
  BlacklistClientUser,
  BlacklistClientUsersRequest
> {
  protected override feature = 'blacklist-clients-users';
  protected override endPoint = `/blacklist-clients-users/`;
}
